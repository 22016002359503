@import "styles/colors";

.scroll-layout {
  overflow: auto;
  padding: 0 11px 0 0;
}

.fade-out-on-scroll {
  z-index: 10;
  content: '';
  position: absolute;
  width: 100%;
  height: 26px;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(238, 251, 255, 0) 0%, #EEFBFF 100%);
  pointer-events: none;

  &.light {
    background: linear-gradient(180deg, rgba(238, 251, 255, 0) 25%, $primary-0 100%);
  }
}

.fade-out-on-scroll-vertical {
  z-index: 10;
  content: '';
  position: absolute;
  width: 26px;
  height: 100%;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(238, 251, 255, 0) 40%, rgba(255, 255, 255, 0.5) 100%);
  pointer-events: none;

  &.light {
    background: linear-gradient(90deg, rgba(238, 251, 255, 0) 40%, rgba(255, 255, 255, 0.5) 100%);
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $stroke-1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 61, 175, 0.2)
}
