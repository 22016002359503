@import "styles/colors";

.mat-mdc-snack-bar-container {
  margin: 0 17px 40px 23px !important;

  .mdc-snackbar__surface {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

.mdc-snackbar__label {
  padding: 0 !important;
}
