.simple-button {
  border: none;
  background: none;
  user-select: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }



  &.disabled {

    cursor: unset;
    color: #c9c9c9;

    &:hover {
      background-color: unset;
    }
  }
}
