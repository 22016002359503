@import "styles/colors";
@import "styles/variables";
@import "styles/mixins";

.button {
  width: 100%;
  height: 38px;
  background: $primary-50;
  border: 1px solid $stroke-1;
  border-radius: 3px;
  cursor: pointer;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $primary-0;
  @include transition($animation-duration);

  &:hover, &:active {
    background: $primary-100;
  }
}
