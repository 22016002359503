@import "styles/colors";
@import "styles/mixins";
@import "styles/fonts";

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

.cdk-overlay-backdrop-with-blur {
  transition: .4s cubic-bezier(.25,.8,.25,1);
  background: rgba(203, 241, 255, 0.35);
  backdrop-filter: blur(2px);
}

.cdk-overlay-transparent-backdrop {
  background: transparent;
  backdrop-filter: none;
  transition: none;
  cursor: default;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  align-items: flex-end;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}



mat-calendar {
  background: $primary-0;
  border: 1px solid $stroke-1;
  box-shadow: 0 2px 5px 1px $shadow-1;
  border-radius: 3px;
  margin-top: 6px;
  height: auto !important;

  &.calendar-panel-hidden {
    display: none;
  }

  .mat-calendar-header {
    padding: 0 14px 0 0;
  }

  .mat-calendar-period-button {
    pointer-events: none;
  }

  .mdc-button__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: $additional-90;
  }

  .mat-mdc-icon-button {
    height: 24px;
    width: 24px;
    font-size: 24px;

    .mat-mdc-button-touch-target {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .mat-calendar-previous-button::after, .mat-calendar-next-button::after {
    margin: 7.5px;
    color: $additional-90;
  }

  .mat-calendar-previous-button {
    margin-right: 16px;
  }

  .mat-calendar-body-cell-content, .mat-calendar-body-label, .mat-calendar-table-header th {
    font-family: $base-font-family;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .mat-calendar-period-button {
    .mdc-button__label {
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .mat-calendar-arrow {
      visibility: hidden;
      width: 8px;
      margin-top: -2px;
      margin-left: 12px;
    }
  }

  thead.mat-calendar-table-header {
    position: relative;
    &:after {
      position: absolute;
      left: -8px;
      content: " ";
      display: block;
      height: 2px;
      width: 296px;
      background: $stroke-1;
    }
  }

  mat-multi-year-view {
    thead.mat-calendar-table-header {
      &:after {
        top: -2px;
      }
    }
  }

  .mat-calendar-table-header th {
    color: $additional-50;
    padding-bottom: 2px;
  }

  .mat-calendar-body-cell-content {
    border: none;
    color: $additional-80;

    &:hover {
      border: 1px solid $primary-40;
    }

    &.mat-calendar-body-selected {
      background: $primary-50;
      color: $primary-0 !important;
    }

    &.mat-calendar-body-today {
      border: 1px solid $primary-50;
    }
  }

  .mat-calendar-body-label {
    color: $additional-50;
    text-transform: lowercase;
    padding: 0 0 0 8px;
    line-height: 0;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content {
      color: $stroke-1;
      &:hover {
        border: none;
      }
    }
  }
}

