$primary-100: #363755;
$primary-70: #004763;
$primary-50: #01A6E6;
$primary-45: #57CDFB;
$primary-40: #CBF1FF;
$primary-30: #EEFBFF;
$primary-0: #FFFFFF;

$additional-100: #000000;
$additional-90: #333333;
$additional-80: #52546F;
$additional-50: #9899A8;
$additional-40: #E0E0E5;

$red-60: #FFCFCB;
$red-50: #FFF3F2;
$red-40: #FFF9F9;

$yellow-60: #FDFDBC;
$yellow-50: #FFFFF2;

$accent: #E41212;
$accent-2: #669B7D;

$success: #95FFA5;
$error: #FF9898;
$stroke-1: rgba(0, 61, 175, 0.15);
$shadow-1: rgba(137, 154, 187, 0.2);
$table-header: #d9e2f3;
$table-footer: #E9F2FF;
$table-important: #C0FFF9;
$table-accent: #EAFFFD;


