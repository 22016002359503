@import "styles/mixins";
@import "styles/colors";

.list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  position: relative;
  padding-right: 11px;
  padding-bottom: 62px;
  overflow: auto;
  margin-top: 24px;

  @include large {
    margin: 24px 11px 0 24px;
  }
}

.list-no-data {
  margin: 24px;
  font-size: 14px;
  color: $additional-90;
}

.list-paginator-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  background: transparent;
  z-index: 2;

  @include large {
    left: 35%;
    transform: unset;
  }
}
