@import "styles/colors";

.field {
  &__name {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $additional-80;
  }
}
