@import "styles/colors";

@mixin xsmall {
  @media (min-width: 320px) { @content; }
}
@mixin small {
  @media (min-width: 576px) { @content; }
}
@mixin medium {
  @media (min-width: 768px) { @content; }
}
@mixin large {
  @media (min-width: 1024px) { @content; }
}
@mixin extra-large {
  @media (min-width: 1366px) { @content; }
}
@mixin full-hd {
  @media (min-width: 1920px) { @content; }
}
@mixin sticky-top {
  position: sticky;
  top: -1px;
  left: 0;
  background-color: $primary-0;
  z-index: 10;
}
@mixin table-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 162px);
  max-height: calc(100vh - 162px);
  padding-top: 24px;

  @include large {
    padding: 24px;
    min-height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
  }
}
@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
@mixin animation($x...){
  -webkit-animation: $x;
  -moz-animation: $x;
  -o-animation: $x;
  animation: $x;
}
