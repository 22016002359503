@import "styles/colors";
@import "styles/variables";
@import "styles/mixins";

.mobile-panel {
  position: fixed !important;
  bottom: 0;
  left: 0;
  @include animation(custom-dialog-enter $animation-duration ease);
}

@keyframes custom-dialog-enter {
  from {
    transform: translateY(100%) translateY(0);
  }

  to {
    transform: none;
  }
}

.inline-dialog {
  width: 282px;
  display: flex;
  flex-direction: column;
  margin-top: -1px;
  margin-left: 4px;
  background: $primary-0;
  border: 1px solid $stroke-1;
  box-shadow: 0 2px 5px 1px rgba(137, 154, 187, 0.2);
  border-radius: 3px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    background: $primary-30;
    border-radius: 2px 2px 0 0;
    border-bottom: 1px solid $stroke-1;
    position: relative;

    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: $additional-100;

    .icon {
      position: absolute;
      top: 7px;
      right: 9px;
      width: 12px;
      height: 12px;
      cursor: pointer;
      color: $additional-80;
    }
  }

  &__footer {
    display: flex;
    gap: 16px;
    margin: 16px 20px 15px;
  }

  &__button {
    width: 114px;
    height: 30px;
    border-radius: 2px;
    background: $primary-0;
    padding: 6px 12px;
    text-align: center;
    border: none;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    text-transform: capitalize;
    @include transition($animation-duration);

    &:hover {
      background: $primary-40;
    }
  }
}
